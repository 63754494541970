import { cn } from '~/lib/utils'

export const LogoImageMini = ({
  className,
  width = 40,
}: {
  className?: string
  width?: number
}) => {
  return (
    <svg
      width={width}
      viewBox="0 0 36 36"
      className={cn('fill-current', className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 16C0 8.45753 0 4.68629 2.34315 2.34315C4.68629 0 8.45753 0 16 0H20C27.5425 0 31.3137 0 33.6569 2.34315C36 4.68629 36 8.45753 36 16V20C36 27.5425 36 31.3137 33.6569 33.6569C31.3137 36 27.5425 36 20 36H16C8.45753 36 4.68629 36 2.34315 33.6569C0 31.3137 0 27.5425 0 20V16Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7241 8.68967C9.81035 8.68967 7.44827 11.0518 7.44827 13.9655C7.44827 16.8793 9.81035 19.2414 12.7241 19.2414C14.0224 19.2414 15.2116 18.7721 16.1306 17.9944L17.1825 18.7833C18.2668 19.5965 19.8228 19.2292 20.429 18.0169L21.3684 16.1379H25.6144L24.7117 14.2759H22.2995L23.8965 11.0817L27.9764 19.2414H30.0384L25.2844 9.69383C24.7126 8.55014 23.0805 8.55013 22.5086 9.69383L18.7635 17.1841C18.6769 17.3573 18.4546 17.4098 18.2997 17.2936L17.3197 16.5587C17.7528 15.7927 18 14.9076 18 13.9655C18 11.0518 15.6379 8.68967 12.7241 8.68967ZM9.31034 13.9655C9.31034 12.0802 10.8387 10.5517 12.7241 10.5517C14.6095 10.5517 16.1379 12.0802 16.1379 13.9655C16.1379 14.4888 16.0204 14.9839 15.8104 15.4267L14.2759 14.2757L13.1586 15.7654L14.5799 16.8314C14.0457 17.1781 13.4087 17.3793 12.7241 17.3793C10.8387 17.3793 9.31034 15.8509 9.31034 13.9655Z"
        fill="white"
      />
      <path
        d="M9.74702 23.5524H10.76V22.8771H9.74702V20.9544H8.96853V22.8771H8.08688V23.5524H8.96853V27.2385C8.96853 27.5574 9.04044 27.7919 9.18426 27.9419C9.32807 28.0858 9.55005 28.1577 9.85019 28.1577C10.0065 28.1577 10.2347 28.1327 10.5349 28.0826V27.4167H10.0847C9.9346 27.4167 9.84081 27.4042 9.80329 27.3792C9.76577 27.3479 9.74702 27.2666 9.74702 27.1353V23.5524Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1375 25.569H16.6208C16.602 24.6936 16.3426 24.0058 15.8423 23.5055C15.3421 22.999 14.7199 22.7458 13.9758 22.7458C13.2317 22.7458 12.6065 23.0116 12.1 23.543C11.5997 24.0745 11.3496 24.7123 11.3496 25.4564C11.3496 26.2005 11.5997 26.8383 12.1 27.3698C12.6002 27.895 13.2349 28.1577 14.004 28.1577C14.5605 28.1577 15.0388 28.0295 15.439 27.7731C15.8392 27.5105 16.1862 27.1072 16.4801 26.5632L15.8236 26.2255C15.3921 27.0321 14.7668 27.4355 13.9477 27.4355C13.4662 27.4355 13.0504 27.2666 12.7003 26.929C12.3563 26.5851 12.1688 26.1317 12.1375 25.569ZM15.1201 23.8619C15.4515 24.1058 15.6641 24.4466 15.7579 24.8843H12.2313C12.5189 23.9589 13.1004 23.4961 13.9758 23.4961C14.4073 23.4961 14.7887 23.6181 15.1201 23.8619Z"
        fill="white"
      />
      <path
        d="M22.6835 26.9477C22.4146 27.3417 22.0613 27.6418 21.6236 27.8481C21.1922 28.0545 20.7232 28.1577 20.2167 28.1577C19.4289 28.1577 18.7692 27.8982 18.2377 27.3792C17.7125 26.8602 17.4498 26.2255 17.4498 25.4752C17.4498 24.7248 17.7156 24.0839 18.2471 23.5524C18.7786 23.0147 19.4664 22.7458 20.3105 22.7458C20.8108 22.7458 21.2704 22.8552 21.6893 23.0741C22.1145 23.2929 22.4459 23.5993 22.6835 23.9933L22.0738 24.3778C21.6174 23.7838 21.0202 23.4868 20.2824 23.4868C19.7009 23.4868 19.2132 23.6744 18.8192 24.0495C18.4253 24.4184 18.2283 24.878 18.2283 25.4283C18.2283 25.9785 18.4222 26.4506 18.8098 26.8446C19.1975 27.2385 19.6884 27.4355 20.2824 27.4355C20.9952 27.4355 21.5955 27.1353 22.0832 26.535L22.6835 26.9477Z"
        fill="white"
      />
      <path
        d="M27.4619 27.9888H28.2404V25.3626C28.2404 24.6686 28.1653 24.1558 28.0153 23.8244C27.8652 23.4868 27.6401 23.2241 27.34 23.0366C27.0398 22.8427 26.7053 22.7458 26.3364 22.7458C25.6423 22.7458 25.042 23.0459 24.5355 23.6462V20.9544H23.7571V27.9888H24.5355V26.0942C24.5355 25.4189 24.5637 24.9687 24.62 24.7436C24.6825 24.5185 24.7919 24.3059 24.9482 24.1058C25.1046 23.9057 25.2921 23.7494 25.511 23.6368C25.7298 23.5243 25.9581 23.468 26.1957 23.468C26.4395 23.468 26.6459 23.5149 26.8147 23.6087C26.9898 23.6962 27.1274 23.8276 27.2274 24.0026C27.3275 24.1715 27.39 24.3528 27.415 24.5466C27.4463 24.7405 27.4619 25.075 27.4619 25.5502V27.9888Z"
        fill="white"
      />
    </svg>
  )
}
