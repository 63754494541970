import type Project from 'types/src/project'

import { createContext } from 'react'

const ProjectContext = createContext<{
  project: Maybe<Project>
  setProject: (project: Maybe<Project>) => void
}>({
  project: undefined,
  setProject: (_) => _,
})

export default ProjectContext
