import { flush } from './flush'
import { group } from './group'
import { identify } from './identify'
import { page } from './page'
import { track } from './track'

export { makeAnalyticsMiddleware } from './makeAnalyticsMiddleware'
export { useAnalytics } from './useAnalytics'

/**
 * Server-side analytics methods, prepopulated with the user's credentials.
 */
const analytics = { identify, page, track, group, flush }

export default analytics
