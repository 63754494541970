'use client'

import type UserSession from '~/core/session/types/user-session'

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuIcon,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
  Icon,
  If,
} from '@repo/ui'
import Link from 'next/link'
import { useMemo } from 'react'

import { ProfileAvatar } from '~/components/ProfileAvatar'
import configuration from '~/configuration'
import useSignOut from '~/core/hooks/use-sign-out'
import GlobalRole from '~/core/session/types/global-role'
import {
  DARK_THEME_CLASSNAME,
  LIGHT_THEME_CLASSNAME,
  SYSTEM_THEME_CLASSNAME,
  useTheme,
} from '~/core/theming'
import Trans from '~/core/ui/Trans'
import useCurrentOrganization from '~/lib/organizations/hooks/use-current-organization'
import useCurrentProject from '~/lib/projects/hooks/use-current-project'

const ProfileDropdown: React.FCC<{
  userSession: UserSession
}> = ({ userSession }) => {
  const signOut = useSignOut()

  const signedInAsLabel = useMemo(() => {
    const displayName = userSession?.data?.displayName || undefined
    const email = userSession?.auth?.user.email || undefined
    const phone = userSession?.auth?.user.phone || undefined

    return displayName ?? email ?? phone
  }, [userSession])

  const isSuperAdmin = useMemo(() => {
    return userSession?.auth?.user.app_metadata.role === GlobalRole.SuperAdmin
  }, [userSession])

  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        className={
          'flex cursor-pointer items-center space-x-2 focus:outline-none select-none'
        }
      >
        <ProfileAvatar size="medium" user={userSession} />
      </DropdownMenuTrigger>

      <DropdownMenuContent
        className={'!min-w-[14rem]'}
        collisionPadding={{ left: 20 }}
        align="end"
        side="bottom"
      >
        <DropdownMenuItem className={'!h-11 rounded-none'} clickable={false}>
          <div
            className={'flex flex-col justify-start truncate text-left text-xs'}
          >
            <div className={'text-gray-500'}>Signed in as</div>

            <div>
              <span className={'block truncate'}>{signedInAsLabel}</span>
            </div>
          </div>
        </DropdownMenuItem>

        <DropdownMenuSeparator />

        <If condition={configuration.enableThemeSwitcher}>
          <ThemeSelectorSubMenu />
        </If>

        <If condition={isSuperAdmin}>
          <AdminItems />
        </If>

        <DropdownMenuSeparator />

        <DropdownMenuItem
          role={'button'}
          className={'cursor-pointer'}
          onClick={signOut}
        >
          <span className={'flex w-full items-center space-x-2'}>
            <DropdownMenuIcon name="ArrowLeftOnRectangle" />

            <span>
              <Trans i18nKey={'auth:signOut'} />
            </span>
          </span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

const hackofficeProjectUrl = (projectId: string) =>
  `${process.env.NEXT_PUBLIC_HACKOFFICE_URL}dashboard/projects/${projectId}`
function AdminItems() {
  const org = useCurrentOrganization()
  const proj = useCurrentProject()

  return (
    <>
      <DropdownMenuSub>
        <DropdownMenuSubTrigger>
          <div className="flex flex-row flex-nowrap">
            <DropdownMenuIcon name="BuildingLibrary" />
            Admin
          </div>
        </DropdownMenuSubTrigger>
        <DropdownMenuPortal>
          <DropdownMenuSubContent>
            <DropdownMenuGroup>
              <DropdownMenuLabel>Admin</DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuItem>
                <Link
                  className={'flex h-full w-full items-center'}
                  href={'/admin'}
                >
                  <DropdownMenuIcon name="Home" />
                  Dasboard
                </Link>
              </DropdownMenuItem>

              {org && (
                <>
                  <DropdownMenuItem>
                    <Link
                      className={'flex h-full w-full items-center'}
                      href={`/admin/organizations/${org.uuid}`}
                      title={`"${org.name}" organization in admin`}
                    >
                      <DropdownMenuIcon name="UserGroup" />
                      Organization
                    </Link>
                  </DropdownMenuItem>
                </>
              )}
              {proj && (
                <>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem>
                    <a
                      className={'flex h-full w-full items-center'}
                      href={hackofficeProjectUrl(proj.id)}
                      title={`"${proj.name}" project in hackoffice`}
                      target="_blank"
                      rel="noopener referrer noreferrer"
                    >
                      <DropdownMenuIcon name="Beaker" />
                      Hackoffice{' '}
                      <Icon
                        name="ArrowRight"
                        size="extra-small"
                        className="mx-1.5"
                      />{' '}
                      Project
                    </a>
                  </DropdownMenuItem>
                </>
              )}
            </DropdownMenuGroup>
          </DropdownMenuSubContent>
        </DropdownMenuPortal>
      </DropdownMenuSub>
    </>
  )
}

function ThemeSelectorSubMenu() {
  const [_, setTheme] = useTheme()

  return (
    <DropdownMenuSub>
      <DropdownMenuSubTrigger>
        <div className="flex flex-row flex-nowrap">
          <DropdownMenuIcon name="PaintBrush" />
          <Trans i18nKey={'common:theme'} />
        </div>
      </DropdownMenuSubTrigger>

      <DropdownMenuSubContent>
        <DropdownMenuItem
          className={'cursor-pointer'}
          onClick={() => setTheme(LIGHT_THEME_CLASSNAME)}
        >
          <DropdownMenuIcon name="Sun" />
          <Trans i18nKey={'common:lightTheme'} />
        </DropdownMenuItem>

        <DropdownMenuItem
          className={'cursor-pointer'}
          onClick={() => setTheme(DARK_THEME_CLASSNAME)}
        >
          <DropdownMenuIcon name="Moon" />
          <Trans i18nKey={'common:darkTheme'} />
        </DropdownMenuItem>

        <DropdownMenuItem
          className={'cursor-pointer'}
          onClick={() => setTheme(SYSTEM_THEME_CLASSNAME)}
        >
          <DropdownMenuIcon name="ComputerDesktop" />
          <Trans i18nKey={'common:systemTheme'} />
        </DropdownMenuItem>
      </DropdownMenuSubContent>
    </DropdownMenuSub>
  )
}

export default ProfileDropdown
