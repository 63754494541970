import Link from 'next/link'

import { LogoImageMini } from './LogoImageMini'

export const Logo: React.FCC<{
  href?: string
  className?: string
  width?: number
}> = ({ href, className, width = 40 }) => {
  return (
    <Link href={href ?? '/'}>
      <LogoImageMini className={className} width={width} />
    </Link>
  )
}
