'use client'

import { flush } from './flush'
import { group } from './group'
import { identify } from './identify'
import { page } from './page'
import { track } from './track'

const analytics = {
  identify,
  page,
  track,
  group,
  flush,
}

/**
 * Client-side analytics methods that requires csrfToken to be passed to the underlying
 * server actions.
 */
export const useAnalytics = () => {
  return analytics
}
