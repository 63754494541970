import type UserSession from '~/core/session/types/user-session'

import { Avatar, AvatarFallback, AvatarImage } from '@repo/ui'

type ProfileAvatarProps = ({ user?: UserSession } | { text?: string }) & {
  size?: 'small' | 'medium'
}

export const ProfileAvatar: React.FCC<ProfileAvatarProps> = ({
  size = 'small',
  ...props
}) => {
  if ('user' in props && props.user) {
    const photoUrl = props.user.data?.photoUrl

    return (
      <Avatar size={size}>
        {photoUrl ? <AvatarImage src={photoUrl} /> : null}
        <AvatarFallback size={size} name={getUserInitials(props.user)} />
      </Avatar>
    )
  }

  if ('text' in props && props.text) {
    return (
      <Avatar>
        <AvatarFallback name={props.text[0]} />
      </Avatar>
    )
  }

  return null
}

function getUserInitials(session: Maybe<UserSession>) {
  const displayName = getDisplayName(session)

  return displayName[0] ?? ''
}

function getDisplayName(session: Maybe<UserSession>) {
  if (!session) {
    return ''
  }

  return (
    session.data?.displayName ??
    session.auth?.user.email ??
    session.auth?.user.phone ??
    ''
  )
}
