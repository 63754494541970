import { useContext } from 'react'

import ProjectContext from '~/lib/contexts/project'
import useCurrentOrganization from '~/lib/organizations/hooks/use-current-organization'

export default function useCurrentProject() {
  const org = useCurrentOrganization()
  const res = useContext(ProjectContext)
  const currentProjectIrrespectiveOfOrg = res?.project

  // Ensure the current project is part of the current organization
  return org?.projects?.find(
    (project) => project.id === currentProjectIrrespectiveOfOrg?.id,
  )
}
