import { useAnalytics } from '@repo/analytics/next'
import { deleteCookie } from 'cookies-next'
import { useCallback } from 'react'

import configuration from '~/configuration'
import useSupabase from '~/core/hooks/use-supabase'

import useCsrfToken from './use-csrf-token'

/**
 * @name useSignOut
 */
function useSignOut() {
  const client = useSupabase()
  const csrfToken = useCsrfToken()
  const analytics = useAnalytics()

  return useCallback(async () => {
    await analytics.track({
      event: 'user_signed_out',
      properties: {},
      csrfToken,
    })
    await client.auth.signOut()
    deleteCookie(configuration.cookies.IMPERSONATED_BY)
    deleteCookie(configuration.cookies.ORGANIZATION_ID)
    deleteCookie(configuration.cookies.CURRENT_PROJECT_COOKIE_NAME)
  }, [analytics, client.auth, csrfToken])
}

export default useSignOut
